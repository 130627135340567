import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Table, Space, Modal, Form, Input, Select } from 'antd';
import {setAccountList,getAccountList,saveOrUpdateAccount,deleteByIds,searchAccount} from "@/store/modules/AccountStore"
import config from '@/utils/config'

const AccountManage = () => {

  /**
   * 准备columns和dataSource
   */
  const columns = [
    {
      title: '用户帐号',
      dataIndex: 'accountCode',
    },
    {
      title: '用户姓名',
      dataIndex: 'realName',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
    },
    {
      title: '上次登录',
      dataIndex: 'loginTime',
    }
  ];
  const [dataSource,setDataSource] = useState([])
  const {accountList} = useSelector(state=>state.accountList)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getAccountList())
  },[dispatch])
  useEffect(()=>{
     setDataSource(accountList.map((account)=>(
        {key:account.id,...account}
     )))
  },[accountList])

  /**
   * 分页用
   */
  const [pagination,setPagination] = useState({});
  useEffect(()=>{
    setPagination({
      current:1,
      pageSize:5,
      total:dataSource.length,
      pageSizeOptions:["5","10","15","20"],
      showQuickJumper:true,
      showSizeChanger:true,
    })
  },[dataSource])
  const handleTableChange= (pagination)=>{
    setPagination(pagination)
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  //添加或更新用
  const [form] = Form.useForm();
  /**
   * 实现对话框
   */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const showModal = () => {
    setModalTitle("添加数据");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form
    .validateFields()
    .then(values=>{
        console.log(values);
        // setDataSource([...dataSource,{key:dataSource.length+1,...values}]);
        /**
         * 保存数据
         */
        saveOrUpdateAccount(values,dispatch);
        
        setIsModalOpen(false);
        form.resetFields();
    })
    .catch(info=>{
        console.log("验证失败",info);
    })   
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  //条件查询用
  /**
   * 实现对话框
   */
  const [searchForm] = Form.useForm();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchModalTitle, setSearchModalTitle] = useState("");
  const showSearchModal = () => {
    setSearchModalTitle("条件查询");
    setIsSearchModalOpen(true);
  };
  const handleSerachOk = () => {
    searchForm
    .validateFields()
    .then(values=>{
        console.log(values);
        // setDataSource([...dataSource,{key:dataSource.length+1,...values}]);

         // 过滤掉值为 undefined 的字段
        const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([_, value]) => value !== undefined)
        );
        /**
         * 查询数据
         */
        searchAccount(filteredValues,dispatch);
        
        setIsSearchModalOpen(false);
        searchForm.resetFields();
    })
    .catch(info=>{
        console.log("验证失败",info);
    })   
  };
  const handleSearchCancel = () => {
    setIsSearchModalOpen(false);
    searchForm.resetFields();
  };

  return (
    <div>
        <Flex gap="middle" vertical>
          <Flex align="center" gap="small">
              <Button onClick={showModal} color="primary" variant="solid">
                添加
              </Button>
              <Button onClick={()=>{deleteByIds(selectedRowKeys,dispatch)}} color="danger" variant="solid">
                删除
              </Button>
              <Button onClick={showSearchModal} color="primary" variant="solid">
                查询
              </Button>
          </Flex>
          <Table 
                onChange={handleTableChange}
                pagination={pagination} 
                rowSelection={rowSelection} 
                columns={columns} 
                dataSource={dataSource} 
                scroll={{y:360}}/>
        </Flex>

        {/* 添加或更新用 */}
        <Modal width={720} centered title={modalTitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            okText={"提交"}
            cancelText={"取消"}
        >
            <Form
              form={form}
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 21,
              }}
              layout="horizontal"
            >
              
              <Form.Item style={{display:"none"}} label="id" name="id">
                <Input />
              </Form.Item>
              <Form.Item label="用户账号" name="accountCode" rules={[
                {required:true, message: '不能为空！'} ,
                { min: 6, message: '最小长度为6！' },
                { max: 20, message: '最大长度为20！' },
                {
                  validator: async (_, value) => {
                    if (!value) {
                      return Promise.reject(new Error('Username is required!'));
                    }
                    try {
                      const response = await fetch(config.apiBaseUrl+"/accountRest/isAccountCodeExist?po.accountCode="+value);
                      const data = await response.json();
            
                      if (data.valid) {
                        return Promise.reject(new Error('用户账号已被使用!'));
                      }
                    } catch (error) {
                      return Promise.reject(new Error('Network error, please try again later.'));
                    }
                    return Promise.resolve();
                  },
                },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item label="用户密码" name="password" rules={[
                {required:true, message: '不能为空！'} ,
                { min: 6, message: '最小长度为6！' },
                { max: 20, message: '最大长度为20！' },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item label="用户姓名" name="realName" rules={[
                {required:true, message: '不能为空！'} ,
                { min: 2, message: '最小长度为2！' },
                { max: 10, message: '最大长度为10！' },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item label="手机号码" name="phone" rules={[
                {required:true, message: '不能为空！'}
                ]}>
                <Input />
              </Form.Item>
            
            </Form>
        </Modal>

        {/* 条件查询用 */}
        <Modal width={720} centered title={searchModalTitle} open={isSearchModalOpen} onOk={handleSerachOk} onCancel={handleSearchCancel}
            okText={"提交"}
            cancelText={"取消"}
        >
            <Form
                  form={searchForm}
                  labelCol={{
                    span: 3,
                  }}
                  wrapperCol={{
                    span: 21,
                  }}
                  layout="horizontal"
                >
                                
                  <Form.Item label="用户帐号" name="accountCode">
                    <Input />
                  </Form.Item>
                  <Form.Item label="手机号码" name="phone">
                    <Input />
                  </Form.Item>
            
            </Form>
        </Modal>
    </div>
  );
};
export default AccountManage;
