import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  BranchesOutlined,
  UserOutlined,
  ContainerOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, theme } from 'antd';
import {Outlet,useNavigate} from "react-router-dom"
const { Header, Sider, Content, Footer } = Layout;

const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate = useNavigate()

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '/mainLayout/AccountManage',
              icon: <UserOutlined />,
              label: '用户管理',
              onClick:function({key}){
                navigate(key)
              }
            },
            {
              key: '/mainLayout/AizhiaizhiManage',
              icon: <ContainerOutlined />,
              label: '爱知爱智书友会',
              onClick:function({key}){
                navigate(key)
              }
            },
            {
              key: '/mainLayout/ITInterviewAppManage',
              icon: <BranchesOutlined />,
              label: 'IT从业者面试宝典',
              onClick:function({key}){
                navigate(key)
              }
            },
            {
              key: '/login',
              icon: <LogoutOutlined />,
              label: '退出系统',
              onClick:function({key}){
                navigate(key)
              }
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            height: '75vh',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          
          <Outlet/>

        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          爱知爱智Copyright © 2018 蜀ICP备2020026701号-1
        </Footer>
      </Layout>
    </Layout>
  );
};
export default MainLayout;
