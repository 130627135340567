import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row,Col,Card } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PhoneSearch from '@/phone/ITInterviewAppPhoneList/PhoneSearch';
import {getITInterviewAppList} from "@/store/modules/ITInterviewAppStore"
import config from '@/utils/config'

export default function ITInterviewAppPhoneList() {

    const {iTInterviewAppList} = useSelector(state=>state.iTInterviewAppList)
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getITInterviewAppList())
    },[dispatch])

  return (
    <div>
    <PhoneSearch/>
    <Scrollbars
      style={{ width: '100%', height: '100vh' }}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            backgroundColor: "transparent"
          }}
        />
      )}
    >
        <Row>
            {
                iTInterviewAppList.map((iTInterviewApp) => (
                    <Col key={iTInterviewApp.id} style={{padding:"20px"}} className="gutter-row" xs={24} sm={24} md={6} lg={6}>
                        <a href={iTInterviewApp.courseUrl}>
                        <Card
                            hoverable
                            style={{
                                width: "100%"
                            }}
                            cover={<img src={config.apiBaseUrl+iTInterviewApp.courseImg}/>}
                        >
                            <h3>{iTInterviewApp.courseTitle}</h3>
                            <p>{iTInterviewApp.courseIntro}</p>
                        </Card>
                        </a>
                    </Col> 
                ))
            }
        </Row>
    </Scrollbars>
    </div>
  )
}
