import { configureStore } from "@reduxjs/toolkit";
import iTInterviewAppStoreReducer from "./modules/ITInterviewAppStore";
import aizhiaizhiStoreReducer from "./modules/AizhiaizhiStore";
import accountStoreReducer from "./modules/AccountStore";

const store = configureStore({
    reducer:{
        iTInterviewAppList:iTInterviewAppStoreReducer,
        aizhiaizhiList:aizhiaizhiStoreReducer,
        accountList:accountStoreReducer
    }
})

export default store;