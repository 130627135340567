import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row,Col,Card } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PhoneSearch from '@/phone/AizhiaizhiPhoneList/PhoneSearch';
import {getAizhiaizhiList} from "@/store/modules/AizhiaizhiStore"
import config from '@/utils/config'

export default function AizhiaizhiPhoneList() {

  const {aizhiaizhiList} = useSelector(state=>state.aizhiaizhiList)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getAizhiaizhiList())
  },[dispatch])

  return (
    <div>
    <PhoneSearch/>
    <Scrollbars
      style={{ width: '100%', height: '100vh' }}
      renderThumbVertical={({ style, ...props }) => (
        <div
          {...props}
          style={{
            ...style,
            backgroundColor: "transparent"
          }}
        />
      )}
    >
        <Row>
            {
                aizhiaizhiList.map((aizhiaizhi) => (
                    <Col key={aizhiaizhi.id} style={{padding:"20px"}} className="gutter-row" xs={24} sm={24} md={6} lg={6}>
                        <a href={aizhiaizhi.bookUrl}>
                        <Card
                            hoverable
                            style={{
                                width: "100%"
                            }}
                            cover={<img src={config.apiBaseUrl+aizhiaizhi.bookImg}/>}
                        >
                            <h3>{aizhiaizhi.bookTitle}</h3>
                            <p>{aizhiaizhi.bookIntro}</p>
                        </Card>
                        </a>
                    </Col> 
                ))
            }
        </Row>
    </Scrollbars>
    </div>
  )
}
