import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex, Table, Space, Modal, Form, Input, Select } from 'antd';
import {setAizhiaizhiList,getAizhiaizhiList,saveOrUpdateAizhiaizhi,deleteByIds,searchAizhiaizhi} from "@/store/modules/AizhiaizhiStore"
import PhotoClipUpload from '@/components/PhotoClipUpload';
import config from '@/utils/config'

const AizhiaizhiManage = () => {

  /**
   * 修改使用
   */
  const updateById= (record)=>{
    // 映射真实值到显示值
    const transformedRecord = {
      ...record,
      isFree: options.find((item) => item.value == record.isFree)?.value
    };
    form.setFieldsValue(transformedRecord);
    setModalTitle("修改数据");
    setIsModalOpen(true);
}

  const options = [
    { label: "免费", value: "1" },
    { label: "付费", value: "0" },
  ];
  const columns = [
    {
      title: '书籍标题',
      dataIndex: 'bookTitle',
    },
    {
      title: '书籍介绍',
      dataIndex: 'bookIntro',
    },
    {
      title: '是否付费',
      dataIndex: 'isFree',
      render: (_, record) => {
        return options.find((item) => item.value == record.isFree)?.label;
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="small">
            <Button onClick={()=>{updateById(record)}} color="primary" variant="solid" size='small'>
              修改
            </Button>
            <Button onClick={()=>{deleteByIds(record.id,dispatch)}} color="danger" variant="solid" size='small'>
              删除
            </Button>
            <Button onClick={()=>{showImgModal(record.id)}} color="primary" variant="solid" size='small'>
              上传封面
            </Button>
        </Space>
      ),
    }
  ];
  const [dataSource,setDataSource] = useState([])
  const {aizhiaizhiList} = useSelector(state=>state.aizhiaizhiList)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getAizhiaizhiList())
  },[dispatch])
  useEffect(()=>{
     setDataSource(aizhiaizhiList.map((aizhiaizhi)=>(
        {key:aizhiaizhi.id,...aizhiaizhi}
     )))
  },[aizhiaizhiList])

  /**
   * 分页用
   */
  const [pagination,setPagination] = useState({});
  useEffect(()=>{
    setPagination({
      current:1,
      pageSize:5,
      total:dataSource.length,
      pageSizeOptions:["5","10","15","20"],
      showQuickJumper:true,
      showSizeChanger:true,
    })
  },[dataSource])
  const handleTableChange= (pagination)=>{
    setPagination(pagination)
  }

  /**
   * 全选用
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  
  //添加或更新用
  /**
   * 实现对话框
   */
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const showModal = () => {
    setModalTitle("添加数据");
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form
    .validateFields()
    .then(values=>{
        console.log(values);
        // setDataSource([...dataSource,{key:dataSource.length+1,...values}]);
        /**
         * 保存数据
         */
        saveOrUpdateAizhiaizhi(values,dispatch);
        
        setIsModalOpen(false);
        form.resetFields();
    })
    .catch(info=>{
        console.log("验证失败",info);
    })   
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  //条件查询用
  /**
   * 实现对话框
   */
  const [searchForm] = Form.useForm();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [searchModalTitle, setSearchModalTitle] = useState("");
  const showSearchModal = () => {
    setSearchModalTitle("条件查询");
    setIsSearchModalOpen(true);
  };
  const handleSerachOk = () => {
    searchForm
    .validateFields()
    .then(values=>{
        console.log(values);
        // setDataSource([...dataSource,{key:dataSource.length+1,...values}]);

         // 过滤掉值为 undefined 的字段
        const filteredValues = Object.fromEntries(
          Object.entries(values).filter(([_, value]) => value !== undefined)
        );
        /**
         * 查询数据
         */
        searchAizhiaizhi(filteredValues,dispatch);
        
        setIsSearchModalOpen(false);
        searchForm.resetFields();
    })
    .catch(info=>{
        console.log("验证失败",info);
    })   
  };
  const handleSearchCancel = () => {
    setIsSearchModalOpen(false);
    searchForm.resetFields();
  };

   //图片上传用
  /**
   * 实现对话框
   */
  const [isImgModalOpen, setIsImgModalOpen] = useState(false);
  const [imgModalTitle, setImgModalTitle] = useState("");
  const [bookId, setBookId] = useState("");
  const showImgModal = (recordId) => {
    setBookId(recordId)
    setImgModalTitle("图片上传");
    setIsImgModalOpen(true);
  };
  const handleImgOk = () => {
    setIsImgModalOpen(false);
  };
  const handleImgCancel = () => {
    setIsImgModalOpen(false);
  };

  return (
    <div>
    <Flex gap="middle" vertical>
      <Flex align="center" gap="small">
          <Button onClick={showModal} color="primary" variant="solid">
            添加
          </Button>
          <Button onClick={()=>{deleteByIds(selectedRowKeys,dispatch)}} color="danger" variant="solid">
            删除
          </Button>
          <Button onClick={showSearchModal} color="primary" variant="solid">
            查询
          </Button>
      </Flex>
      <Table 
        onChange={handleTableChange}
        pagination={pagination} 
        rowSelection={rowSelection} 
        columns={columns} 
        dataSource={dataSource} 
        scroll={{y:360}}
      />
    </Flex>
    {/* 添加或更新用 */}
    <Modal width={720} centered title={modalTitle} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
        okText={"提交"}
        cancelText={"取消"}
    >
        <Form
          form={form}
          labelCol={{
            span: 3,
          }}
          wrapperCol={{
            span: 21,
          }}
          layout="horizontal"
        >
          
          <Form.Item style={{display:"none"}} label="id" name="id">
            <Input />
          </Form.Item>
          <Form.Item label="书籍标题" name="bookTitle" rules={[{required:true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="书籍介绍" name="bookIntro" rules={[{required:true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="听书路径" name="bookUrl" rules={[{required:true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="是否付费" name="isFree" rules={[{required:true}]}>
            <Select>
              <Select.Option value="1">免费</Select.Option>
              <Select.Option value="0">付费</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="资源标题" name="sourceTitle" rules={[{required:true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="资源介绍" name="sourceIntro" rules={[{required:true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="资源路径" name="sourceUrl" rules={[{required:true}]}>
            <Input />
          </Form.Item>
          <Form.Item label="资源价格" name="sourcePrice" rules={[{required:true}]}>
            <Input />
          </Form.Item>     
          <Form.Item label="下载路径" name="buyUrl" rules={[{required:true}]}>
            <Input />
          </Form.Item>             
        
        </Form>
    </Modal>
    {/* 条件查询用 */}
    <Modal width={720} centered title={searchModalTitle} open={isSearchModalOpen} onOk={handleSerachOk} onCancel={handleSearchCancel}
        okText={"提交"}
        cancelText={"取消"}
    >
        <Form
              form={searchForm}
              labelCol={{
                span: 3,
              }}
              wrapperCol={{
                span: 21,
              }}
              layout="horizontal"
            >
                            
              <Form.Item label="书籍标题" name="bookTitle">
                <Input />
              </Form.Item>
              
              <Form.Item label="是否付费" name="isFree">
                <Select>
                  <Select.Option value="1">免费</Select.Option>
                  <Select.Option value="0">付费</Select.Option>
                </Select>
              </Form.Item>
        
        </Form>
    </Modal>
    {/* 图片上传用 */}
    <Modal width={720} centered title={imgModalTitle} open={isImgModalOpen}  onOk={handleImgOk} onCancel={handleImgCancel}
        okText={"确认"}
        cancelText={"取消"}
    >
        <PhotoClipUpload dataId={bookId} currentUrl={config.apiBaseUrl+"/aizhiaizhiDataRest/saveOrUpdate"}/>
    </Modal>

   </div>
  );
};
export default AizhiaizhiManage;
