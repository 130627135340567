import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';

export default function index() {
    const [isLogin,setIsLogin] = useState(false);

    return (
        <div>
            <Outlet context={{isLogin,setIsLogin}}/>
        </div>
    )
}
