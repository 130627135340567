import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import {searchAizhiaizhi} from "@/store/modules/AizhiaizhiStore"

export default function PhoneSearch() {

  const dispatch = useDispatch()
  const handleChange= (bookTitle)=>{
    const values= {"bookTitle":bookTitle}
    searchAizhiaizhi(values,dispatch)
  }

  return (
    <div>
      <div style={{padding:"10px"}}>
        <Input placeholder="请输入需要查询的资源" onChange={(e)=>{handleChange(e.target.value)}}/>
      </div>
    </div>
  )
}
