import { createSlice } from "@reduxjs/toolkit";
import { message } from 'antd';
import config from '@/utils/config'

const aizhiaizhiStore = createSlice({
     name:"aizhiaizhiStore",
     initialState:{
        aizhiaizhiList:[]
     },
     reducers:{
        setAizhiaizhiList(state,action){
            state.aizhiaizhiList=action.payload
        }
     }
})

const {setAizhiaizhiList} = aizhiaizhiStore.actions;

/**
 * 获取数据
 */
const getAizhiaizhiList= ()=>{
    return async(dispatch)=>{
        try{
            const response = await fetch(config.apiBaseUrl+"/aizhiaizhiDataRest/getAll");
            if(!response.ok){
                throw new Error("后端数据接口异常！")
            }
            const aizhiaizhiPageModel = await response.json();
            dispatch(setAizhiaizhiList(aizhiaizhiPageModel.rows))
        }catch(error){
            console.log(error)
        }
    }
}
/**
 * 保存或修改数据
 */
const saveOrUpdateAizhiaizhi= (values,dispatch)=>{
    fetch(config.apiBaseUrl+"/aizhiaizhiDataRest/saveOrUpdateAPI",{
        method:"POST",
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        },
        //URLSearchParams将js对象转化为字符串传递
        body:new URLSearchParams(values),
    })
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        message.success("操作成功");
        dispatch(getAizhiaizhiList());
    })
    .catch(error=>{
        message.error(error);
    })
}
/**
 * 删除数据
 */
const deleteByIds= (id,dispatch)=>{
    fetch(config.apiBaseUrl+"/aizhiaizhiDataRest/deleteByIds?ids="+id)
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        message.success("删除成功");
        dispatch(getAizhiaizhiList());
    })
    .catch(error=>{
        message.error(error);
    })
}
/**
 * 条件查询
 */
const searchAizhiaizhi= (values,dispatch)=>{
    fetch(config.apiBaseUrl+"/aizhiaizhiDataRest/getAll",{
        method:"POST",
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        },
        //URLSearchParams将js对象转化为字符串传递
        body:new URLSearchParams(values),
    })
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        dispatch(setAizhiaizhiList(data.rows))
    })
    .catch(error=>{
        message.error(error);
    })
}

export {setAizhiaizhiList,getAizhiaizhiList,saveOrUpdateAizhiaizhi,deleteByIds,searchAizhiaizhi}

const aizhiaizhiStoreReducer = aizhiaizhiStore.reducer;
export default aizhiaizhiStoreReducer;





