import {createBrowserRouter} from "react-router-dom"
import MainLayout from "@/pages/MainLayout"
import AccountManage from "@/pages/AccountManage"
import AizhiaizhiManage from "@/pages/AizhiaizhiManage"
import ITInterviewAppManage from "@/pages/ITInterviewAppManage"
import Main from "@/pages/Main"
import Login from "@/pages/Login"
import ITInterviewAppPhoneList from "@/phone/ITInterviewAppPhoneList"
import AizhiaizhiPhoneList from "@/phone/AizhiaizhiPhoneList"
import ProtectedRoute from "@/router/ProtectedRoute/ProtectedRoute"

const router = createBrowserRouter([

    {
        path:"/iTInterviewAppPhoneList",
        element:<ITInterviewAppPhoneList/>
    },{
        path:"/aizhiaizhiPhoneList",
        element:<AizhiaizhiPhoneList/>
    },{
        path:"/",
        element:<Main/>,
        children:[
            {
                index:true,
                element:<Login/>
            },{
                path:"/login",
                element:<Login/>
            },{
                path:"/mainLayout",
                element:(
                    <ProtectedRoute>
                       <MainLayout/>
                    </ProtectedRoute>
                ),
                children:[
                    {
                        index:true,
                        element:<AccountManage/>
                    },{
                        path:"/mainLayout/AccountManage",
                        element:<AccountManage/>
                    },{
                        path:"/mainLayout/AizhiaizhiManage",
                        element:<AizhiaizhiManage/>
                    },{
                        path:"/mainLayout/ITInterviewAppManage",
                        element:<ITInterviewAppManage/>
                    }
                ]
            },
        ]

    }

])

export default router;