import React, { useEffect, useRef } from "react";
import { Button,message } from 'antd';

const PhotoClipUpload = ({dataId,currentUrl}) => {
  const clipAreaRef = useRef(null); // 裁剪区域的 DOM 节点
  //const [croppedImg, setCroppedImg] = useState(""); // 保存裁剪后的图片

  useEffect(() => {
    // 初始化 PhotoClip
    const $ = window.jQuery; // 确保 jQuery 可用

    $(clipAreaRef.current).photoClip({
      size: [200, 240], // 裁剪区域的大小
      outputSize: [200, 240], // 输出图片的大小
      file: "#fileInput", // 文件选择器
      view: "#preview", // 预览容器
      ok: "#confirmBtn", // 确认按钮
      loadStart: () => console.log("图片加载中..."),
      loadComplete: () => console.log("图片加载完成！"),
      clipFinish: (dataURL) => {
        if(dataURL){
          handleUpload(dataURL,dataId,currentUrl);
        }
      }, // 裁剪完成回调
    });

    return () => {
      // 组件卸载时清理
      $(clipAreaRef.current).photoClip("destroy");
    };
  }, [dataId,currentUrl]);

  //发送到服务器
  const handleUpload = async (dataURL,dataId,currentUrl) => {
      console.log("上传到服务器的图片:", dataURL);
      console.log("courseId:", dataId);
      console.log("currentUrl:", currentUrl);
      //执行上传操作
      $.ajax({
        url:"http://127.0.0.1:8787/accountRest/upLoadImg",//请求路径
        type:"post",//请求方式
        data:{
          imgData:dataURL
        },//请求数据
        dataType:"text",//返回的数据类型
        success:function(text){
          //更新封面路径
          $.ajax({
            url:currentUrl,//请求路径
            type:"post",//请求方式
            data:{
              "po.id":dataId,
              "po.courseImg":text,
              "po.bookImg":text
            },//请求数据
            dataType:"json",//返回的数据类型
            success:function(data){
              $("#fileInput").val("");
              message.success(data.msg);
            }
          });
        }
      });
      
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h2>图片裁剪与上传</h2>
      <div
        ref={clipAreaRef}
        style={{
          width: "300px",
          height: "300px",
          margin: "0 auto",
          border: "1px solid #ccc",
          // position: 'relative'
        }}
      >
        <div id="preview" style={{ width: "100%", height: "100%" }}></div>
      </div>
      <input type="file" id="fileInput" accept="image/*" 
          style={{
            // opacity: '0',
            // position: 'absolute',
            // top:'0px',
            // left:'0px',
            // zIndex:"100",
            // width:'100%',
            // height:'100%',
            // cursor: 'pointer',
          }} 
       />
      <Button id="confirmBtn" type="primary" style={{marginTop:"10px"}}>确认上传</Button>
    </div>
  );
};

export default PhotoClipUpload;
