import { createSlice } from "@reduxjs/toolkit";
import { message } from 'antd';
import config from '@/utils/config'

const iTInterviewAppStore = createSlice({
    name:"iTInterviewAppStore",
    initialState:{
        iTInterviewAppList:[]
    },
    reducers:{
        setITInterviewAppList(state,action){
            state.iTInterviewAppList = action.payload
        }
    }
})

const {setITInterviewAppList} =  iTInterviewAppStore.actions;

/**
 * 分页获取数据
 */
const getITInterviewAppListByPage= (page)=>{
    return async(dispatch)=>{
        try{
            const response = await fetch(config.apiBaseUrl+"/ITInterviewAppRest/list?page="+page+"&rows=5");
            if(!response.ok){
                throw new Error("后端数据接口异常！")
            }
            const iTInterviewAppPageModel = await response.json();
            if(iTInterviewAppPageModel.pageNo<=(iTInterviewAppPageModel.total%iTInterviewAppPageModel.pageSize==0?iTInterviewAppPageModel.total/iTInterviewAppPageModel.pageSize:iTInterviewAppPageModel.total/iTInterviewAppPageModel.pageSize+1)){
                dispatch(setITInterviewAppList(iTInterviewAppPageModel.rows))
            }else{
                message.error("已经是最后一页！");
            }
            
        }catch(error){
            console.log(error)
        }
    }
}

/**
 * 获取数据
 */
const getITInterviewAppList= ()=>{
    return async(dispatch)=>{
        try{
            const response = await fetch(config.apiBaseUrl+"/ITInterviewAppRest/getAll");
            if(!response.ok){
                throw new Error("后端数据接口异常！")
            }
            const iTInterviewAppPageModel = await response.json();
            dispatch(setITInterviewAppList(iTInterviewAppPageModel.rows))
        }catch(error){
            message.error(error)
        }
    }
}
/**
 * 条件查询
 */
const searchInterviewApp= (values,dispatch)=>{
    fetch(config.apiBaseUrl+"/ITInterviewAppRest/getAll",{
        method:"POST",
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        },
        //URLSearchParams将js对象转化为字符串传递
        body:new URLSearchParams(values),
    })
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        dispatch(setITInterviewAppList(data.rows))
    })
    .catch(error=>{
        message.error(error);
    })
}
/**
 * 保存或修改数据
 */
const saveOrUpdateInterviewApp= (values,dispatch)=>{
    fetch(config.apiBaseUrl+"/ITInterviewAppRest/saveOrUpdateAPI",{
        method:"POST",
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        },
        //URLSearchParams将js对象转化为字符串传递
        body:new URLSearchParams(values),
    })
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        message.success("操作成功");
        dispatch(getITInterviewAppList());
    })
    .catch(error=>{
        message.error(error);
    })
}
/**
 * 删除数据
 */
const deleteByIds= (id,dispatch)=>{
    fetch(config.apiBaseUrl+"/ITInterviewAppRest/deleteByIds?ids="+id)
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        message.success("删除成功");
        dispatch(getITInterviewAppList());
    })
    .catch(error=>{
        message.error(error);
    })
}

export {setITInterviewAppList,getITInterviewAppListByPage,getITInterviewAppList,saveOrUpdateInterviewApp,deleteByIds,searchInterviewApp}

const iTInterviewAppStoreReducer = iTInterviewAppStore.reducer;
export default iTInterviewAppStoreReducer;