import React, { useEffect, useState } from 'react'
import { message } from 'antd';
import { useNavigate, useOutletContext } from 'react-router-dom';
import "./index.css"
import { Button, Form, Input } from 'antd';
import config from '@/utils/config'

export default function index() {
    const [username,setUsername] = useState("nwl123");
    const [password,setPassword] = useState("123456a");
    const navigate = useNavigate();
    const {isLogin,setIsLogin} = useOutletContext()
    useEffect(()=>{
        setIsLogin(false)
         // 初始化 PhotoClip
        const $ = window.jQuery; // 确保 jQuery 可用
    })
   
    const handleLogin= ()=>{
        //登录
        $.ajax({
            url:config.apiBaseUrl+"/accountRest/login",//请求路径
            type:"post",//请求方式
            data:{
            "po.accountCode":username,
            "po.password":password
            },//请求数据
            dataType:"json",//返回的数据类型
            success:function(dto){
            if(dto.msgCode==1){
                setIsLogin(true);
                navigate("/mainLayout");
            }else{
                message.error(dto.msg);   
            }
            }
        });
    }

    return (
        <div>
            <div className='mainDiv'>
                {/* <h1>登录</h1>
                <input
                type="text"
                placeholder='用户名'
                value={username}
                onChange={(e)=>setUsername(e.target.value)}
                />
                <br/>
                <input
                type="password"
                placeholder='密码'
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                />
                <br/>
                <button onClick={handleLogin}>登录</button>     */}
            
            <Form
                style={{
                   width:"80%",
                   margin:"30px auto"
                }}
                initialValues={{
                   remember: true,
                }}
            >
                <Form.Item
                    name="username"
                    rules={[
                        {
                        required: true,
                        message: '请输入用户名',
                        },
                    ]}
                    >
                    <Input defaultValue={username} placeholder='请输入用户名' onChange={(e)=>setUsername(e.target.value)}/>
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                        required: true,
                        message: '请输入密码',
                        },
                    ]}
                    >
                    <Input.Password defaultValue={password} placeholder='请输入密码' onChange={(e)=>setPassword(e.target.value)}/>
                </Form.Item>



                <Form.Item>
                    <Button onClick={handleLogin} style={{margin:"5px"}} type="primary" htmlType="submit">
                        登录
                    </Button>
                    <Button style={{margin:"5px"}} type="primary" htmlType="reset">
                        重置
                    </Button>
                </Form.Item>
            </Form>
            </div>
        </div>
    )
}
