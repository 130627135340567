import { createSlice } from "@reduxjs/toolkit";
import { message } from 'antd';
import config from '@/utils/config'

const accountStore = createSlice({
    name:"accountStore",
    initialState:{
        accountList:[]
    },
    reducers:{
        setAccountList(state,action){
            state.accountList = action.payload
        }
    }
});

const {setAccountList,getBaseUrl} =  accountStore.actions;

/**
 * 获取数据
 */
const getAccountList= ()=>{
    return async(dispatch)=>{
        try{
            const response = await fetch(config.apiBaseUrl+"/accountRest/getAll");
            if(!response.ok){
                throw new Error("后端数据接口异常！")
            }
            const accountListPageModel = await response.json();
            dispatch(setAccountList(accountListPageModel.rows))
        }catch(error){
            message.error(error)
        }
    }
}
/**
 * 保存或修改数据
 */
const saveOrUpdateAccount= (values,dispatch)=>{
    fetch(config.apiBaseUrl+"/accountRest/saveOrUpdateAPI",{
        method:"POST",
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        },
        //URLSearchParams将js对象转化为字符串传递
        body:new URLSearchParams(values),
    })
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        message.success("操作成功");
        dispatch(getAccountList());
    })
    .catch(error=>{
        message.error(error);
    })
}
/**
 * 删除数据
 */
const deleteByIds= (id,dispatch)=>{
    fetch(config.apiBaseUrl+"/accountRest/deleteByIds?ids="+id)
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        message.success("删除成功");
        dispatch(getAccountList());
    })
    .catch(error=>{
        message.error(error);
    })
}
/**
 * 条件查询
 */
const searchAccount= (values,dispatch)=>{
    fetch(config.apiBaseUrl+"/accountRest/getAll",{
        method:"POST",
        headers:{
            "Content-Type":"application/x-www-form-urlencoded"
        },
        //URLSearchParams将js对象转化为字符串传递
        body:new URLSearchParams(values),
    })
    .then(response=>{
        if(response.ok){
            return response.json();
        }
        throw new Error("后端数据接口异常！")
    })
    .then(data=>{
        dispatch(setAccountList(data.rows))
    })
    .catch(error=>{
        message.error(error);
    })
}



export {setAccountList,getAccountList,saveOrUpdateAccount,deleteByIds,searchAccount}

const accountStoreReducer = accountStore.reducer;
export default accountStoreReducer;





